/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";

const Map = () => {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <div className="w-full my-[60px]">
        <div className="pt-4 pb-4 md:p-0 flex flex-wrap md:flex-row flex-col justify-between md:justify-between items-center md:w-[80%] md:mx-auto">
          {rpdata?.dbPrincipal?.location?.slice(1).map((item, index) => {
            return (
              <div className="md:w-[32%] text-center">
                <span className="pl-2 text-[30px] font-bold">{item.address}</span>
                <iframe src={item.url} className="w-full h-[500px]" />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Map;
